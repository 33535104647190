import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';

export const UsersTableColumns: ColumnDef<any>[] = [
  {
    accessorKey: 'name',
    header: 'Name',
    cell: ({ row: { original } }) => {
      const { firstname, lastname } = original;
      const type = 'info';
      return (
        <div className="d-flex align-items-center">
          <div>
            <Link to="javascript:void(0)" className="fs-8 fw-bold">
              {firstname} {lastname}
            </Link>
          </div>
        </div>
      );
    },
    meta: {
      headerProps: {
        style: { width: '15%', minWidth: '180px' },
        className: 'ps-4 pe-5 border-start border-end border-translucent'
      },
      cellProps: {
        className:
          'white-space-nowrap fw-semibold ps-4 border-start border-end border-translucent'
      }
    }
  },
  {
    accessorKey: 'email',
    header: () => {
      return (
        <div className="d-inline-flex flex-center">
          <div className="px-1 py-1 bg-success-subtle rounded me-2">
            <FeatherIcon icon="mail" size={16} className="text-success-dark" />
          </div>
          <span>Email</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => {
      const { email } = original;
      return (
        <Link className="text-body-highlight" to={`mailto:${email}`}>
          {email}
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '25%', minWidth: '180px' },
        className: 'ps-4 pe-5 border-end border-translucent'
      },
      cellProps: {
        className:
          'white-space-nowrap fw-semibold ps-4 border-end border-translucent'
      }
    }
  },
  {
    accessorKey: 'createdAt',
    header: 'Create date',
    cell: ({ row: { original } }) => original.createdAt,
    meta: {
      headerProps: {
        style: { width: '15%' },
        className: 'ps-4 pe-5 border-end border-translucent'
      },
      cellProps: {
        className:
          'white-space-nowrap ps-4 text-body-tertiary border-end border-translucent'
      }
    }
  }
];

const UsersTable = () => {
  return (
    <div className="border-top border-translucent">
      <AdvanceTable
        tableProps={{ className: 'phoenix-table fs-9' }}
        rowClassName="hover-actions-trigger btn-reveal-trigger"
      />
      <AdvanceTableFooter pagination className="py-4" />
    </div>
  );
};

export default UsersTable;
