import axios from 'axios';
import { errorHandler } from 'helpers/response-utils';
import { useEffect, useState } from 'react';
import LeadPerformanceTable from './LeadPerformanceTable';

const LeadPerformance = () => {
  const [LeadPerformanceTableData, setLeadPerformanceTableData] = useState([]);
  const token = localStorage.getItem('token');

  useEffect(() => {
    getLeadPerformanceMatrixData();
  }, []);

  const getLeadPerformanceMatrixData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/leads/performance`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (response.data['status'] == false) {
      errorHandler(response.data);
      return false;
    }

    setLeadPerformanceTableData(response.data.data.result);
  };

  return (
    <>
      <h4 className="mt-4 mb-3">Lead Performance </h4>
      <LeadPerformanceTable
        LeadPerformanceTableData={LeadPerformanceTableData}
      />
    </>
  );
};

export default LeadPerformance;
