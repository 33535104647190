import LeadConversationChart from './LeadConversationChart';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { errorHandler } from 'helpers/response-utils';
import axios from 'axios';
import { source } from 'static/Lead';

const LeadConversion = () => {
  const token = localStorage.getItem('token');
  const [leadConversionData, setLeadConversionData] = useState([]);

  useEffect(() => {
    getLeadConversionData();
  }, []);

  const getLeadConversionData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/leads/conversion`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (response.data['status'] == false) {
      errorHandler(response.data);
      return false;
    }

    setLeadConversionData(response.data.data.result);
  };

  return (
    <Card className="h-100">
      <Card.Body>
        <h3>Lead Conversion</h3>
        <p className="text-body-tertiary mb-0">&nbsp;</p>
        <LeadConversationChart
          style={{ height: 250, width: '100%' }}
          leadConversionData={leadConversionData}
        />
      </Card.Body>
    </Card>
  );
};

export default LeadConversion;
