import AdClicksChart from './AdClicksChart';
import { Col, Form, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { errorHandler } from 'helpers/response-utils';
import { getDates } from 'helpers/utils';
import { useAppContext } from 'providers/AppProvider';

const LeadJobQuoted = ({ dateRange }: { dateRange: any }) => {
  const token = localStorage.getItem('token');
  const [seriesData, setSeriesData] = useState([]);
  const { getThemeColor } = useAppContext();
  useEffect(() => {
    getLeadJobQuotedByDateRange();
  }, [dateRange]);

  const getLeadJobQuotedByDateRange = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/leads/jobquoted`,
      {
        params: {
          startDate: dateRange.startDate,
          endDate: dateRange.endDate
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (response.data['status'] == false) {
      errorHandler(response.data);
      return false;
    }

    const chartData: any = [];
    response.data.data.result.map((item: any) => {
      const count = item.lead_details.map((leadItem: any) => {
        return leadItem.lead_count;
      });
      chartData.push({
        name: item.name,
        type: 'line',
        data: count,
        showSymbol: false,
        symbol: 'emptyCircle',
        symbolSize: 6,
        itemStyle: {
          color: getThemeColor('primary'),
          borderWidth: 3
        },
        lineStyle: {
          type: 'line',
          width: 3,
          color: getThemeColor('primary')
        }
      });
    });

    setSeriesData(chartData);
  };

  return (
    <div>
      <Row className="align-items-start justify-content-between mb-4 g-3">
        <Col xs="auto">
          <h3>Ad Clicks</h3>
          <p className="text-body-tertiary lh-sm mb-0"></p>
        </Col>
      </Row>
      <AdClicksChart
        style={{ height: '385px', width: '100%' }}
        dates={getDates(dateRange.startDate, dateRange.endDate)}
        seriesData={seriesData}
      />
    </div>
  );
};

export default LeadJobQuoted;
