import axios from 'axios';
import DatePicker from 'components/base/DatePicker';
import { errorHandler } from 'helpers/response-utils';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Unicon from 'components/base/Unicon';
import {
  UilEnvelope,
  UilForwadedCall,
  UilBookmark,
  UilBookmarkFull
} from '@iconscout/react-unicons';
const Analytics = () => {
  const today = new Date();

  const startDate = new Date(today);
  startDate.setDate(today.getDate() - 6);

  const endDate = today;

  const [dateRange, setDateRange] = useState({
    startDate: startDate,
    endDate: endDate
  });

  const [newLead, setNewLead] = useState({ count: 0 });
  const [firstCall, setFirstCall] = useState({ count: 0 });
  const [secondCall, setSecondCall] = useState({ count: 0 });
  const [thirdCall, setThirdCall] = useState({ count: 0 });
  const [mettingBooked, setMettingBooked] = useState({ count: 0 });
  const [quoteFollowUp, setQuoteFollowUp] = useState({ count: 0 });
  const [emailed, setEmailed] = useState({ count: 0 });

  const token = localStorage.getItem('token');

  const setDateRangeState = (selectedDates: any) => {
    if (selectedDates[0]) {
      setDateRange({ ...dateRange, startDate: selectedDates[0] });
    }

    if (selectedDates[1]) {
      setDateRange({ ...dateRange, endDate: selectedDates[1] });
    }
  };

  useEffect(() => {
    getAnalytics();
  }, [dateRange]);

  const getAnalytics = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/leads/analytics`,
      {
        params: {
          startDate: dateRange.startDate,
          endDate: dateRange.endDate
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (response.data['status'] == false) {
      errorHandler(response.data);
      return false;
    }

    setNewLead(response.data['data']['result']['new_lead']);
    setFirstCall(response.data['data']['result']['1_call_contact']);
    setSecondCall(response.data['data']['result']['2_call_contact']);
    setThirdCall(response.data['data']['result']['3_call_contact']);

    setEmailed(response.data['data']['result']['unable_to_make_contact']);
    setMettingBooked(response.data['data']['result']['metting_booked']);
    setQuoteFollowUp(response.data['data']['result']['quote_issue']);
  };

  return (
    <div>
      <div className="mb-6">
        <Row className="align-items-center justify-content-between g-3 mb-6">
          <Col md="auto">
            <h2 className="mb-0">Analytics</h2>
          </Col>
          <Col md={4}>
            <DatePicker
              name="datepicker"
              onChange={setDateRangeState}
              options={{
                mode: 'range',
                defaultDate: [startDate.toDateString(), endDate.toDateString()]
              }}
            />
          </Col>
        </Row>
        <div className="px-3 mb-6">
          <Row className="justify-content-between">
            <Col
              xs={6}
              md={4}
              xxl={2}
              className="text-center border-translucent border-start-xxl border-end-xxl-0 border-bottom-xxl-0 border-end border-bottom pb-4 pt-4 pb-xxl-0"
            >
              <Unicon
                icon={UilForwadedCall}
                className={`text-primary mb-1`}
                size={31.25}
              />
              <h1 className="fs-5 mt-3">{newLead.count}</h1>
              <p className="fs-9 mb-0">New Leads</p>
            </Col>
            <Col
              xs={6}
              md={4}
              xxl={2}
              className="text-center border-start-xxl border-translucent border-end-xxl-0 border-bottom-xxl-0 border-end border-bottom pb-4 pt-4 pb-xxl-0"
            >
              <Unicon
                icon={UilForwadedCall}
                className={`text-primary mb-1`}
                size={31.25}
              />
              <h1 className="fs-5 mt-3">{firstCall.count}</h1>
              <p className="fs-9 mb-0">1st call / contact</p>
            </Col>
            <Col
              xs={6}
              md={4}
              xxl={2}
              className="text-center border-start-xxl border-translucent border-end-xxl-0 border-bottom-xxl-0 border-bottom pb-4 pt-4 pb-xxl-0"
            >
              <Unicon
                icon={UilForwadedCall}
                className={`text-primary mb-1`}
                size={31.25}
              />
              <h1 className="fs-5 mt-3">{secondCall.count}</h1>
              <p className="fs-9 mb-0">2nd call / contact</p>
            </Col>
            <Col
              xs={6}
              md={4}
              xxl={2}
              className="text-center border-start-xxl border-translucent border-end-xxl-0 border-bottom-xxl-0 border-end border-bottom pb-4 pt-4 pb-xxl-0"
            >
              <Unicon
                icon={UilForwadedCall}
                className={`text-primary mb-1`}
                size={31.25}
              />
              <h1 className="fs-5 mt-3">{thirdCall.count}</h1>
              <p className="fs-9 mb-0">3rd call / contact</p>
            </Col>
            <Col
              xs={6}
              md={4}
              xxl={2}
              className="text-center border-start-xxl border-translucent border-end-xxl-0 border-bottom-xxl-0 border-end border-bottom pb-4 pt-4 pb-xxl-0"
            >
              <Unicon
                icon={UilEnvelope}
                className={`text-primary mb-1`}
                size={31.25}
              />
              <h1 className="fs-5 mt-3">{emailed.count}</h1>
              <p className="fs-9 mb-0">Unable to make contact</p>
            </Col>
            <Col
              xs={6}
              md={4}
              xxl={2}
              className="text-center border-start-xxl border-translucent border-end-xxl-0 border-bottom-xxl-0 border-bottom pb-4 pt-4 pb-xxl-0"
            >
              <Unicon
                icon={UilBookmark}
                className={`text-primary mb-1`}
                size={31.25}
              />
              <h1 className="fs-5 mt-3">{mettingBooked.count}</h1>
              <p className="fs-9 mb-0">Meeting / appointment follow up</p>
            </Col>
            <Col
              xs={6}
              md={4}
              xxl={2}
              className="text-center border-start-xxl border-translucent border-end-xxl-0 border-bottom-xxl-0 border-end pb-4 pt-4 pb-xxl-0"
            >
              <Unicon
                icon={UilBookmarkFull}
                className={`text-success mb-1`}
                size={31.25}
              />
              <h1 className="fs-5 mt-3">{quoteFollowUp.count}</h1>
              <p className="fs-9 mb-0">Quote follow up</p>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
