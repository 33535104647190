import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Badge from 'components/base/Badge';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { LeadDataType } from 'data/crm/leadsTableData';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { BadgeBg } from 'components/base/Badge';

export const leadsTableColumns: ColumnDef<any>[] = [
  {
    accessorKey: 'assignTo',
    header: 'Assigned to name',
    cell: ({ row: { original } }) => {
      const { assignTo } = original;
      const type = 'info';
      return (
        <div className="d-flex align-items-center">
          <div>
            {assignTo != null ? (
              <Link to="#" className="fs-8 fw-bold">
                {assignTo.firstname} {assignTo.lastname}
              </Link>
            ) : (
              ''
            )}
          </div>
        </div>
      );
    },
    meta: {
      headerProps: {
        style: { width: 'auto' },
        className: 'ps-4 border-start border-end border-translucent ps-0'
      },
      cellProps: {
        className:
          'ps-4 border-start border-end border-translucent white-space-nowrap ps-0'
      }
    }
  },
  {
    accessorKey: 'email',
    header: () => {
      return (
        <div className="d-inline-flex flex-center">
          <div className="px-1 py-1 bg-success-subtle rounded me-2">
            <FeatherIcon icon="mail" size={16} className="text-success-dark" />
          </div>
          <span>Email</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => {
      const { email } = original;
      return (
        <Link className="text-body-highlight" to={`mailto:${email}`}>
          {email}
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: 'auto' },
        className: 'ps-4 pe-5 border-end border-translucent'
      },
      cellProps: {
        className:
          'white-space-nowrap fw-semibold ps-4 border-end border-translucent'
      }
    }
  },
  {
    accessorKey: 'phone',
    header: () => {
      return (
        <div className="d-inline-flex flex-center">
          <div className="px-1 py-1 bg-primary-subtle rounded me-2">
            <FeatherIcon icon="phone" size={16} className="text-primary-dark" />
          </div>
          <span>Phone</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => {
      const { phone } = original;
      return (
        <Link className="text-body-highlight" to={`tel:${phone}`}>
          {phone}
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: 'auto' },
        className: 'ps-4 pe-5 border-end border-translucent'
      },
      cellProps: {
        className:
          'white-space-nowrap fw-semibold ps-4 border-end border-translucent'
      }
    }
  },
  {
    accessorKey: 'createdAt',
    header: 'Create date',
    cell: ({ row: { original } }) => original.createdAt,
    meta: {
      headerProps: {
        style: { width: 'auto' },
        className: 'ps-4 pe-5'
      },
      cellProps: {
        className: 'white-space-nowrap ps-4 text-body-tertiary'
      }
    }
  },
  {
    accessorKey: 'status',
    header: () => {
      return (
        <div className="d-inline-flex flex-center">
          <div className="px-1 py-1 bg-info-subtle rounded me-2">
            <FeatherIcon icon="user" size={16} className="text-info-dark" />
          </div>
          <span>Status</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => {
      const { status } = original;
      return (
        <div className="d-flex align-items-center">
          <Badge variant="phoenix" bg={status.color}>
            {status.type}
          </Badge>
        </div>
      );
    },
    meta: {
      headerProps: {
        style: { width: 'auto' },
        className: 'ps-4 border-translucent'
      },
      cellProps: {
        className: 'white-space-nowrap fw-semibold ps-4 border-translucent'
      }
    }
  },
  {
    id: 'leadDropdown',
    cell: ({ row: { original } }) => {
      return (
        <RevealDropdownTrigger>
          <RevealDropdown>
            <Dropdown.Item href={'/admin/crm/leads/edit/' + original.id}>
              Edit
            </Dropdown.Item>
            <Dropdown.Item href="remove" className="text-danger">
              Remove
            </Dropdown.Item>
          </RevealDropdown>
        </RevealDropdownTrigger>
      );
    },
    meta: {
      headerProps: {
        style: { width: 'auto' },
        className: 'text-end border-end pe-4 ps-4 border-translucent'
      },
      cellProps: {
        className: 'text-end border-end pe-4 ps-4 border-translucent'
      }
    }
  }
];

const LeadsTable = () => {
  return (
    <div className="border-top border-translucent">
      <AdvanceTable
        tableProps={{ className: 'phoenix-table fs-9' }}
        rowClassName="hover-actions-trigger btn-reveal-trigger"
      />
      <AdvanceTableFooter pagination className="py-4" />
    </div>
  );
};

export default LeadsTable;
