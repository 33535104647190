import React, { useState } from 'react';
import Button from 'components/base/Button';
import { Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as formik from 'formik';
import * as yup from 'yup';

const SignUpForm = ({ layout }: { layout: 'simple' | 'card' | 'split' }) => {
  const { Formik } = formik;
  const navigate = useNavigate();

  const schema = yup.object().shape({
    companyName: yup
      .string()
      .min(4, 'Too Short Company name!')
      .max(30, 'Too Long Company name!')
      .required('Company name is required!'),
    companyEmail: yup
      .string()
      .email('Invalid Company email!')
      .required('Company Email is required!'),
    industry: yup
      .string()
      .min(4, 'Too Short Industry!')
      .max(30, 'Too Long Industry!')
      .required('Industry is required!'),
    firstname: yup
      .string()
      .min(4, 'Too Short Firstname!')
      .max(30, 'Too Long Firstname!')
      .required('Firstname is required!'),
    lastname: yup
      .string()
      .min(4, 'Too Short Lastname!')
      .max(30, 'Too Long Lastname!')
      .required('Lastname is required!'),
    email: yup.string().email('Invalid email!').required('Email is required!'),
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters long')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'
      ),
    confirmpassword: yup
      .string()
      .required('Confirm Password is required')
      .oneOf([yup.ref('password')], 'Passwords must match'),
    terms: yup.bool().required().oneOf([true], 'terms must be accepted')
  });

  const onSubmit = async (values: any) => {
    const formData = values;
    try {
      const data = {
        company: {
          name: formData.companyName,
          email: formData.companyEmail,
          industry: formData.industry
        },
        user: {
          firstname: formData.firstname,
          lastname: formData.lastname,
          email: formData.email,
          password: formData.password,
          confirmpassword: formData.confirmpassword
        }
      };
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/register`,
        data
      );

      if (response.data['status'] == false) {
        toast.error(response.data['error']['message']);
        return false;
      }

      toast(response.data['data']['message']);
      navigate('/auth/login');
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-body-highlight">Sign Up</h3>
        <p className="text-body-tertiary">Create your account today</p>
      </div>
      <Formik
        validationSchema={schema}
        onSubmit={values => onSubmit(values)}
        initialValues={{
          companyName: '',
          companyEmail: '',
          industry: '',
          firstname: '',
          lastname: '',
          email: '',
          password: '',
          confirmpassword: '',
          terms: false
        }}
      >
        {({ handleSubmit, handleChange, values, touched, errors, dirty }) => (
          <Form noValidate onSubmit={handleSubmit} className="needs-validation">
            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="companyName">Company Name</Form.Label>
              <Form.Control
                id="companyName"
                type="text"
                name="companyName"
                placeholder="Company name"
                value={values.companyName}
                onChange={handleChange}
                isValid={touched.companyName && !errors.companyName}
                isInvalid={touched.companyName && !!errors.companyName}
              />
              <Form.Control.Feedback type="invalid">
                {' '}
                {errors.companyName}{' '}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="companyEmail">
                Company email address
              </Form.Label>
              <Form.Control
                id="companyEmail"
                type="email"
                name="companyEmail"
                className="form-icon-input"
                placeholder="name@example.com"
                value={values.companyEmail}
                onChange={handleChange}
                isValid={touched.companyEmail && !errors.companyEmail}
                isInvalid={touched.companyEmail && !!errors.companyEmail}
              />
              <Form.Control.Feedback type="invalid">
                {' '}
                {errors.companyEmail}{' '}
              </Form.Control.Feedback>
            </Form.Group>
            <Row className="g-3 mb-3">
              <Col sm={layout === 'card' ? 12 : 6} lg={6}>
                <Form.Group>
                  <Form.Label htmlFor="fname">Firstname</Form.Label>
                  <Form.Control
                    id="fname"
                    type="text"
                    name="firstname"
                    className="form-icon-input"
                    placeholder="Firstname"
                    value={values.firstname}
                    onChange={handleChange}
                    isValid={touched.firstname && !errors.firstname}
                    isInvalid={touched.firstname && !!errors.firstname}
                  />
                  <Form.Control.Feedback type="invalid">
                    {' '}
                    {errors.firstname}{' '}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={layout === 'card' ? 12 : 6} lg={6}>
                <Form.Group>
                  <Form.Label htmlFor="lname"></Form.Label>
                  <Form.Control
                    id="lname"
                    type="text"
                    name="lastname"
                    className="form-icon-input"
                    placeholder="Lastname"
                    value={values.lastname}
                    onChange={handleChange}
                    isValid={touched.lastname && !errors.lastname}
                    isInvalid={touched.lastname && !!errors.lastname}
                  />
                  <Form.Control.Feedback type="invalid">
                    {' '}
                    {errors.lastname}{' '}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="email">Email address</Form.Label>
              <Form.Control
                id="email"
                type="email"
                name="email"
                className="form-icon-input"
                placeholder="name@example.com"
                value={values.email}
                onChange={handleChange}
                isValid={touched.email && !errors.email}
                isInvalid={touched.email && !!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {' '}
                {errors.email}{' '}
              </Form.Control.Feedback>
            </Form.Group>
            <Row className="g-3 mb-3">
              <Col sm={layout === 'card' ? 12 : 6} lg={6}>
                <Form.Group>
                  <Form.Label htmlFor="password">Password</Form.Label>
                  <Form.Control
                    id="password"
                    type="password"
                    name="password"
                    className="form-icon-input"
                    placeholder="Password"
                    value={values.password}
                    onChange={handleChange}
                    isValid={touched.password && !errors.password}
                    isInvalid={touched.password && !!errors.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {' '}
                    {errors.password}{' '}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={layout === 'card' ? 12 : 6} lg={6}>
                <Form.Group>
                  <Form.Label htmlFor="confirmPassword">
                    Confirm Password
                  </Form.Label>
                  <Form.Control
                    id="confirmpassword"
                    type="password"
                    name="confirmpassword"
                    className="form-icon-input"
                    placeholder="Confirm Password"
                    value={values.confirmpassword}
                    onChange={handleChange}
                    isValid={touched.confirmpassword && !errors.confirmpassword}
                    isInvalid={
                      touched.confirmpassword && !!errors.confirmpassword
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {' '}
                    {errors.confirmpassword}{' '}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="name">Industry</Form.Label>
              <Form.Control
                id="industry"
                type="text"
                name="industry"
                className="form-icon-input"
                placeholder="Industry"
                value={values.industry}
                onChange={handleChange}
                isValid={touched.industry && !errors.industry}
                isInvalid={touched.industry && !!errors.industry}
              />
              <Form.Control.Feedback type="invalid">
                {' '}
                {errors.industry}{' '}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Check type="checkbox" className="mb-3">
              <Form.Check.Input
                type="checkbox"
                name="terms"
                id="terms"
                required
                onChange={handleChange}
                isValid={touched.terms && !errors.terms}
                isInvalid={touched.terms && !!errors.terms}
              />
              <Form.Check.Label
                htmlFor="teams"
                className="fs-9 text-transform-none"
              >
                I accept the <Link to="#!">terms </Link>and{' '}
                <Link to="#!">privacy policy</Link>
              </Form.Check.Label>
              <Form.Control.Feedback type="invalid">
                {' '}
                {errors.terms}{' '}
              </Form.Control.Feedback>
            </Form.Check>
            <Button type="submit" variant="primary" className="w-100 mb-3">
              {' '}
              Sign up{' '}
            </Button>
            <div className="text-center">
              <Link to={`/auth/login`} className="fs-9 fw-bold">
                Sign in to an existing account
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SignUpForm;
