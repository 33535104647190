import { Navigate } from 'react-router-dom';
import MainLayout from 'layouts/MainLayout';
import { RouteObject, createBrowserRouter } from 'react-router-dom';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import Error404 from 'pages/error/Error404';
import SimpleSignIn from 'pages/pages/authentication/simple/SignIn';
import SimpleSignUp from 'pages/pages/authentication/simple/SignUp';
import ResetPassword from 'pages/pages/authentication/simple/ResetPassword';
import SimpleSignOut from 'pages/pages/authentication/simple/SignOut';
import SimpleResetPassword from 'pages/pages/authentication/simple/ResetPassword';
import SimpleForgotPassword from 'pages/pages/authentication/simple/ForgotPassword';
import App from 'App';

import Leads from 'pages/admin/dashboard/Leads';
import AddLead from 'pages/admin/dashboard/AddLead';
import EditLead from 'pages/admin/dashboard/EditLead';
import Users from 'pages/admin/dashboard/Users';
import Crm from 'pages/admin/dashboard/Crm';
import AddUser from 'pages/admin/dashboard/AddUser';
import Analytics from 'pages/admin/dashboard/Analytics';

import Companies from 'pages/superadmin/dashboard/Companies';
import SALeads from 'pages/superadmin/dashboard/Leads';
import SAAddLead from 'pages/superadmin/dashboard/AddLead';
import SAEditLead from 'pages/superadmin/dashboard/EditLead';

import UserLeads from 'pages/user/dashboard/UserLeads';
import UserEditLead from 'pages/user/dashboard/EditLead';

import ECharts from 'pages/modules/charts/ECharts';

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '',
        element: (
          <MainLayoutProvider>
            {' '}
            <MainLayout />{' '}
          </MainLayoutProvider>
        ),
        children: [
          {
            path: 'admin',
            children: [
              {
                path: '',
                element: <Navigate to="crm/dashboard" replace />
              },
              {
                path: 'crm/dashboard',
                element: <Crm />
              },
              {
                path: 'crm/analytics',
                element: <Analytics />
              },
              {
                path: 'crm/leads',
                element: <Leads />
              },
              {
                path: 'crm/leads/add',
                element: <AddLead />
              },
              {
                path: 'crm/leads/edit/:id',
                element: <EditLead />
              },
              {
                path: 'crm/users',
                element: <Users />
              },
              {
                path: 'crm/users/add',
                element: <AddUser />
              }
            ]
          },
          {
            path: 'superadmin',
            children: [
              {
                path: '',
                element: <Navigate to="crm/dashboard" replace />
              },
              {
                path: 'crm/dashboard',
                element: <Companies />
              },
              {
                path: 'crm/leads',
                element: <SALeads />
              },
              {
                path: 'crm/leads/add',
                element: <SAAddLead />
              },
              {
                path: 'crm/leads/edit/:id',
                element: <SAEditLead />
              }
            ]
          },
          {
            path: 'user',
            children: [
              {
                path: '',
                element: <Navigate to="crm/leads" replace />
              },
              {
                path: 'crm/leads',
                element: <UserLeads />
              },
              {
                path: 'crm/leads/edit/:id',
                element: <UserEditLead />
              }
            ]
          }
        ]
      },
      {
        path: '/auth/',
        children: [
          {
            path: 'login',
            element: <SimpleSignIn />
          },
          {
            path: 'register',
            element: <SimpleSignUp />
          },
          {
            path: 'reset/:token',
            element: <ResetPassword />
          },
          {
            path: 'logout',
            element: <SimpleSignOut />
          },
          {
            path: 'forgot-password',
            element: <SimpleForgotPassword />
          },
          {
            path: 'reset-password',
            element: <SimpleResetPassword />
          }
          // {
          //   path: 'lock-screen',
          //   element: <SimpleLockScreen />
          // },
          // {
          //   path: '2FA',
          //   element: <SimpleTwoFA />
          // }
        ]
      },

      {
        path: '*',
        element: <Error404 />
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
