import FeatherIcon from 'feather-icons-react';
import { getDates } from 'helpers/utils';
import { Col, Row } from 'react-bootstrap';
import BasicLineChart from './BasicLineChart';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { errorHandler } from 'helpers/response-utils';

const NewLeads = ({ dateRange }: { dateRange: any }) => {
  const token = localStorage.getItem('token');
  const [leadsCountsArray, setLeadsCountsArray] = useState([]);
  const [leadsCounts, setLeadsCounts] = useState(0);

  useEffect(() => {
    getLeadByDateRange();
  }, [dateRange]);

  const getLeadByDateRange = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/leads/bydate`,
      {
        params: {
          startDate: dateRange.startDate,
          endDate: dateRange.endDate
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (response.data['status'] == false) {
      errorHandler(response.data);
      return false;
    }

    const res = response.data.data.result.map((ele: any) => {
      return ele.lead_count;
    });
    const sum = res.reduce(
      (accumulator: number, currentValue: number) => accumulator + currentValue,
      0
    );
    setLeadsCounts(sum);
    setLeadsCountsArray(res);
  };

  return (
    <>
      <div className="mb-3">
        <h3>New Leads</h3>
      </div>
      <Row className="g-6">
        <Col md={12}>
          <div className="d-flex gap-2 align-items-center">
            <FeatherIcon icon="zap" className="text-primary" />{' '}
            <h4 className="text-body-tertiary mb-0">
              New Leads :{' '}
              <span className="text-body-emphasis">{leadsCounts}</span>
            </h4>
          </div>
          <div className="pb-0 pt-4">
            <BasicLineChart
              data={leadsCountsArray}
              dates={getDates(dateRange.startDate, dateRange.endDate)}
              style={{ height: 110, width: '100%' }}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default NewLeads;
