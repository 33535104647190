import { faCalendar, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'components/base/DatePicker';
import CrmStatCard from './CrmStatCard';
import LeadSources from './LeadSources';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { errorHandler } from 'helpers/response-utils';
import axios from 'axios';
import ContactsBySource from './ContactsBySource';
import LeadByStatus from './Graph/LeadByStatus/LeadByStatus';
import NewLeads from './Graph/NewLeadByDate/NewLeads';
import LeadJobQuoted from './Graph/LeadJobQuotedSourceByDate/LeadJobQuoted';
import LeadPerformance from './Graph/LeadPerformance/LeadPerformance';
import LeadConversion from './Graph/LeadConversion/LeadConversion';
import { source, contactSourceData } from 'static/Lead';

const Crm = () => {
  const today = new Date();

  const startDate = new Date(today);
  startDate.setDate(today.getDate() - 6);

  const endDate = today;

  const [dateRange, setDateRange] = useState({
    startDate: startDate,
    endDate: endDate
  });

  const [newLead, setNewLead] = useState({ count: 0 });
  const [firstCall, setFirstCall] = useState({ count: 0 });
  const [secondCall, setSecondCall] = useState({ count: 0 });
  const [thirdCall, setThirdCall] = useState({ count: 0 });
  const [mettingBooked, setMettingBooked] = useState({ count: 0 });
  const [quoteFollowUp, setQuoteFollowUp] = useState({ count: 0 });
  const [emailed, setEmailed] = useState({ count: 0 });
  const [wonJob, setWonJob] = useState({ count: 0 });
  const token = localStorage.getItem('token');
  const [leadBySourceData, setLeadBySourceData] = useState();
  const [leadByStatuseData, setLeadByStatuseData] = useState([]);

  const setDateRangeState = (selectedDates: any) => {
    if (selectedDates[0] && selectedDates[1]) {
      setDateRange({
        ...dateRange,
        startDate: selectedDates[0],
        endDate: selectedDates[1]
      });
    }
  };

  useEffect(() => {
    getCRMDashboardData();
    getLeadBySourceChartData();
    getLeadByStatusChartData();
  }, [dateRange]);

  useEffect(() => {
    getLeadBySourceChartData();
    getLeadByStatusChartData();
  }, []);

  const getLeadBySourceChartData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/leads/source`,
      {
        params: {
          startDate: dateRange.startDate,
          endDate: dateRange.endDate
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (response.data['status'] == false) {
      errorHandler(response.data);
      return false;
    }

    mergeArrays(contactSourceData, response.data['data']['result']);
  };

  const getLeadByStatusChartData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/leads/status`,
      {
        params: {
          startDate: dateRange.startDate,
          endDate: dateRange.endDate
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (response.data['status'] == false) {
      errorHandler(response.data);
      return false;
    }

    setLeadByStatuseData(response.data['data']['result']);
  };

  const getCRMDashboardData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/leads/analytics`,
      {
        params: {
          startDate: dateRange.startDate,
          endDate: dateRange.endDate
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (response.data['status'] == false) {
      errorHandler(response.data);
      return false;
    }

    setNewLead(response.data['data']['result']['new_lead']);
    setFirstCall(response.data['data']['result']['1_call_contact']);
    setSecondCall(response.data['data']['result']['2_call_contact']);
    setThirdCall(response.data['data']['result']['3_call_contact']);

    setEmailed(response.data['data']['result']['unable_to_make_contact']);
    setMettingBooked(response.data['data']['result']['metting_booked']);
    setQuoteFollowUp(response.data['data']['result']['quote_issue']);
    setWonJob(response.data['data']['result']['job_won']);
  };

  const mergeArrays = (array1: any, array2: any) => {
    const map = new Map();

    array1.forEach((item: any) => {
      map.set(item.name, { ...item });
    });

    array2.forEach((item: any) => {
      const existingItem = map.get(item.name);
      if (existingItem) {
        existingItem.value += item.value; // Aggregate values
      } else {
        map.set(item.name, { ...item });
      }
    });

    const mergedArray: any = Array.from(map.values());
    setLeadBySourceData(mergedArray);
  };

  return (
    <Row className="mb-4 gy-3 justify-content-between">
      <Col>
        <h2 className="mb-4 text-body-emphasis">CRM Dashboard</h2>
      </Col>
      <Col md={4}>
        <DatePicker
          name="datepicker"
          onChange={setDateRangeState}
          options={{
            mode: 'range',
            defaultDate: [startDate.toDateString(), endDate.toDateString()]
          }}
        />
      </Col>
      <Col xxl={12}>
        <Row className="g-3 mb-6">
          <Col sm={6} md={4} xl={3} xxl={2}>
            <CrmStatCard
              icon={faPhoneAlt}
              color="primary"
              label="New Leads"
              value={newLead.count}
              title="Leads"
              badgeLabel=""
              badgeBg="success"
              footerText=""
            />
          </Col>
          <Col sm={6} md={4} xl={3} xxl={2}>
            <CrmStatCard
              icon={faCalendar}
              color="info"
              label="1st call / contact"
              value={firstCall.count}
              title="Leads"
              badgeLabel=""
              badgeBg="warning"
              footerText=""
            />
          </Col>
          <Col sm={6} md={4} xl={3} xxl={2}>
            <CrmStatCard
              icon={faCalendar}
              color="info"
              label="2nd call / contact"
              value={secondCall.count}
              title="Leads"
              badgeLabel=""
              badgeBg="warning"
              footerText=""
            />
          </Col>
          <Col sm={6} md={4} xl={3} xxl={2}>
            <CrmStatCard
              icon={faCalendar}
              color="info"
              label="3rd call / contact"
              value={thirdCall.count}
              title="Leads"
              badgeLabel=""
              badgeBg="warning"
              footerText=""
            />
          </Col>
          <Col sm={6} md={4} xl={3} xxl={2}>
            <CrmStatCard
              icon={faCalendar}
              color="info"
              label="Unable to make contact"
              value={emailed.count}
              title="Leads"
              badgeLabel=""
              badgeBg="warning"
              footerText=""
            />
          </Col>
          <Col sm={6} md={4} xl={3} xxl={2}>
            <CrmStatCard
              icon={faCalendar}
              color="info"
              label="Meetings booked"
              value={mettingBooked.count}
              title="Leads"
              badgeLabel=""
              badgeBg="warning"
              footerText=""
            />
          </Col>
          <Col sm={6} md={4} xl={3} xxl={2}>
            <CrmStatCard
              icon={faCalendar}
              color="info"
              label="Quotes issued"
              value={quoteFollowUp.count}
              title="Leads"
              badgeLabel=""
              badgeBg="warning"
              footerText=""
            />
          </Col>
          <Col sm={6} md={4} xl={3} xxl={2}>
            <CrmStatCard
              icon={faCalendar}
              color="info"
              label="⁠Jobs won"
              value={wonJob.count}
              title="Leads"
              badgeLabel=""
              badgeBg="warning"
              footerText=""
            />
          </Col>
          {/* <Col className="g-3 mt-4" md={4} xl={6} xxl={4}>
            <div className="border-bottom">
              <h5 className="pb-4 border-bottom">Top 5 Lead Sources</h5>
              <LeadSources />
            </div>
          </Col> */}
        </Row>
      </Col>
      <Col xs={12} xxl={6} className="mb-3 mb-sm-0">
        <ContactsBySource leadBySource={leadBySourceData} />
      </Col>
      <Col xs={12} xxl={6} className="mb-8">
        <NewLeads dateRange={dateRange} />
      </Col>
      <Col xs={12} xxl={6} className="mb-3 mb-sm-0">
        <LeadByStatus leadByStatuseData={leadByStatuseData} />
      </Col>

      <Col xs={12} xxl={6}>
        <LeadJobQuoted dateRange={dateRange} />
      </Col>
      <Col xs={12} xxl={6} className="mb-6 gy-0 gy-xxl-3">
        <LeadPerformance />
      </Col>
      <Col xs={12} xl={5}>
        <LeadConversion />
      </Col>
    </Row>
  );
};

export default Crm;
