import axios from 'axios';
import classNames from 'classnames';
import Button from 'components/base/Button';
import { useState } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as formik from 'formik';
import * as yup from 'yup';

const AddUser = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const { Formik } = formik;

  const schema = yup.object().shape({
    firstname: yup
      .string()
      .min(4, 'Too Short Firstname!')
      .max(30, 'Too Long Firstname!')
      .required('Firstname is required!'),
    lastname: yup
      .string()
      .min(4, 'Too Short Lastname!')
      .max(30, 'Too Long Lastname!')
      .required('Lastname is required!'),
    email: yup.string().email('Invalid email!').required('Email is required!'),
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters long')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'
      ),
    confirmpassword: yup
      .string()
      .required('Confirm Password is required')
      .oneOf([yup.ref('password')], 'Passwords must match')
  });

  const onSubmit = async (values: any) => {
    const formData = values;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/users`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.data['status'] == false) {
        toast.error(response.data['error']['message']);
        return false;
      }

      toast(response.data['data']['message']);
      navigateBack();
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  const navigateBack = () => {
    navigate('/admin/crm/users');
  };

  return (
    <div className="mb-9">
      <div className="border-bottom border-translucent mb-7 mx-n3 px-2 mx-lg-n6 px-lg-6">
        <Row>
          <Col xl={9}>
            <div className="d-sm-flex justify-content-between">
              <h2 className="mb-4">Create a new User</h2>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col xl={9}>
          <Formik
            validationSchema={schema}
            onSubmit={values => onSubmit(values)}
            initialValues={{
              firstname: '',
              lastname: '',
              email: '',
              password: '',
              confirmpassword: ''
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              touched,
              errors,
              dirty
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row className={classNames('g-3', 'mb-6')}>
                  <Col sm={6} md={6}>
                    <FloatingLabel label="Firstname">
                      <Form.Control
                        id="fname"
                        type="text"
                        name="firstname"
                        className="form-icon-input"
                        placeholder="Firstname"
                        value={values.firstname}
                        onChange={handleChange}
                        isValid={touched.firstname && !errors.firstname}
                        isInvalid={touched.firstname && !!errors.firstname}
                      />
                      <Form.Control.Feedback type="invalid">
                        {' '}
                        {errors.firstname}{' '}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                  <Col sm={6} md={6}>
                    <FloatingLabel label="Lastname">
                      <Form.Control
                        id="lname"
                        type="text"
                        name="lastname"
                        className="form-icon-input"
                        placeholder="Lastname"
                        value={values.lastname}
                        onChange={handleChange}
                        isValid={touched.lastname && !errors.lastname}
                        isInvalid={touched.lastname && !!errors.lastname}
                      />
                      <Form.Control.Feedback type="invalid">
                        {' '}
                        {errors.lastname}{' '}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                  <Col sm={12} md={12}>
                    <FloatingLabel label="Email address">
                      <Form.Control
                        id="email"
                        type="email"
                        name="email"
                        className="form-icon-input"
                        placeholder="name@example.com"
                        value={values.email}
                        onChange={handleChange}
                        isValid={touched.email && !errors.email}
                        isInvalid={touched.email && !!errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {' '}
                        {errors.email}{' '}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                  <Col sm={6} md={6}>
                    <FloatingLabel label="Password">
                      <Form.Control
                        id="password"
                        type="password"
                        name="password"
                        className="form-icon-input"
                        placeholder="Password"
                        value={values.password}
                        onChange={handleChange}
                        isValid={touched.password && !errors.password}
                        isInvalid={touched.password && !!errors.password}
                      />
                      <Form.Control.Feedback type="invalid">
                        {' '}
                        {errors.password}{' '}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                  <Col sm={6} md={6}>
                    <FloatingLabel label="Confirm Password">
                      <Form.Control
                        id="confirmpassword"
                        type="password"
                        name="confirmpassword"
                        className="form-icon-input"
                        placeholder="Confirm Password"
                        value={values.confirmpassword}
                        onChange={handleChange}
                        isValid={
                          touched.confirmpassword && !errors.confirmpassword
                        }
                        isInvalid={
                          touched.confirmpassword && !!errors.confirmpassword
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {' '}
                        {errors.confirmpassword}{' '}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                  <div className="text-end">
                    <Button
                      variant="phoenix-primary"
                      className="me-2 px-6"
                      onClick={navigateBack}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" variant="primary">
                      Submit
                    </Button>
                  </div>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};

export default AddUser;
