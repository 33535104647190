import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Avatar from 'components/base/Avatar';
import Badge from 'components/base/Badge';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';

export const CompanyTableColumns: ColumnDef<any>[] = [
  {
    accessorKey: 'name',
    header: 'Name',
    cell: ({ row: { original } }) => {
      const { name, status } = original;
      const type = 'info';
      return (
        <div className="d-flex align-items-center">
          <div>
            <Link to="/apps/crm/lead-details#tasks" className="fs-8 fw-bold">
              {name}
            </Link>
            <div className="d-flex align-items-center">
              <Badge variant="phoenix" bg={type}>
                {status}
              </Badge>
            </div>
          </div>
        </div>
      );
    },
    meta: {
      headerProps: {
        style: { width: '25%' },
        className: 'ps-4 pe-5 border-start border-end border-translucent'
      },
      cellProps: {
        className:
          'white-space-nowrap ps-4 pe-5 border-start border-end border-translucent'
      }
    }
  },
  {
    accessorKey: 'email',
    header: () => {
      return (
        <div className="d-inline-flex flex-center">
          <div className="px-1 py-1 bg-success-subtle rounded me-2">
            <FeatherIcon icon="mail" size={16} className="text-success-dark" />
          </div>
          <span>Email</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => {
      const { email } = original;
      return (
        <Link className="text-body-highlight" to={`mailto:${email}`}>
          {email}
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '20%', minWidth: '180px' },
        className: 'ps-4 pe-5 border-end border-translucent'
      },
      cellProps: {
        className:
          'white-space-nowrap fw-semibold ps-4 border-end border-translucent'
      }
    }
  },
  {
    accessorKey: 'industry',
    header: () => {
      return (
        <div className="d-inline-flex flex-center">
          <div className="px-1 py-1 bg-primary-subtle rounded me-2">
            <FeatherIcon icon="globe" size={16} className="text-primary-dark" />
          </div>
          <span>Industry</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => {
      const { industry } = original;
      return <span>{industry}</span>;
    },
    meta: {
      headerProps: {
        style: { width: '15%', minWidth: '180px' },
        className: 'ps-4 pe-5 border-end border-translucent'
      },
      cellProps: {
        className:
          'white-space-nowrap fw-semibold ps-4 border-end border-translucent'
      }
    }
  },
  {
    accessorKey: 'Register User',
    header: () => {
      return (
        <div className="d-inline-flex flex-center">
          <span>Register User</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => {
      const { users } = original;
      return <span>{users.length}</span>;
    },
    meta: {
      headerProps: {
        style: { width: '15%', minWidth: '180px' },
        className: 'ps-4 pe-5 border-end border-translucent'
      },
      cellProps: {
        className:
          'white-space-nowrap fw-semibold ps-4 border-end border-translucent'
      }
    }
  },
  {
    accessorKey: 'createdAt',
    header: 'Create date',
    cell: ({ row: { original } }) => original.createdAt,
    meta: {
      headerProps: {
        style: { width: '15%' },
        className: 'ps-4 pe-5'
      },
      cellProps: {
        className: 'white-space-nowrap ps-4 text-body-tertiary'
      }
    }
  },
  {
    accessorKey: 'status',
    header: () => {
      return (
        <div className="d-inline-flex flex-center">
          <div className="px-1 py-1 bg-info-subtle rounded me-2">
            <FeatherIcon icon="user" size={16} className="text-info-dark" />
          </div>
          <span>Status</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => original.status,
    meta: {
      headerProps: {
        style: { width: '15%', minWidth: '150px' },
        className: 'ps-4 pe-5 border-end border-translucent'
      },
      cellProps: {
        className:
          'white-space-nowrap fw-semibold ps-4 border-end border-translucent'
      }
    }
  },
  {
    id: 'leadDropdown',
    cell: () => {
      return (
        <RevealDropdownTrigger>
          <RevealDropdown>
            <ActionDropdownItems />
          </RevealDropdown>
        </RevealDropdownTrigger>
      );
    },
    meta: {
      cellProps: {
        className: 'text-end pe-0 ps-4'
      }
    }
  }
];

const CompanyTable = () => {
  return (
    <div className="border-top border-translucent">
      <AdvanceTable
        tableProps={{ className: 'phoenix-table fs-9' }}
        rowClassName="hover-actions-trigger btn-reveal-trigger"
      />
      <AdvanceTableFooter pagination className="py-4" />
    </div>
  );
};

export default CompanyTable;
