import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Icon, UilChartPie, UilCube } from '@iconscout/react-unicons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

export const routes: RouteItems[] = [
  {
    label: 'dashboard',
    horizontalNavLabel: 'home',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'CRM',
        path: '/admin/crm/dashboard',
        pathName: 'crm',
        active: true
      },
      {
        name: 'analytics',
        path: '/admin/crm/analytics',
        pathName: 'crm-analytics',
        active: true
      },
      {
        name: 'leads',
        path: '/admin/crm/leads',
        pathName: 'crm-leads',
        active: true
      },
      {
        name: 'Users',
        path: '/admin/crm/users',
        pathName: 'users-list',
        active: true
      }
    ]
  }
];
