import {
  faFileExport,
  faFilter,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import Button from 'components/base/Button';
import DatePicker from 'components/base/DatePicker';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import LeadsFilterModal from 'components/modals/LeadsFilterModal';
import CompanyTable, {
  CompanyTableColumns
} from 'components/tables/superadmin/Companyable';
import { defaultBreadcrumbItems } from 'data/commonData';
import { errorHandler } from 'helpers/response-utils';
import { parseData } from 'helpers/utils';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Companies = () => {
  const nav = useNavigate();
  const [compnayData, setCompnayData] = useState([]);
  const token = localStorage.getItem('token');

  useEffect(() => {
    getCompanyList();
  }, []);

  const getCompanyList = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/company`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (response.data['status'] == false) {
      errorHandler(response.data);
      return false;
    }

    setCompnayData(response.data['data']['companies']);
  };

  const table = useAdvanceTable({
    data: compnayData,
    columns: CompanyTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false,
    initialState: {
      columnVisibility: {
        status: false,
        designation: false
      }
    }
  });
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  const navigateToCreateLead = () => {
    nav('/admin/crm/leads/add');
  };
  return (
    <div>
      <div className="mb-6">
        <AdvanceTableProvider {...table}>
          <h2 className="mb-4">{compnayData.length} Companies</h2>
          <Row className="g-3 justify-content-between mb-4">
            <Col xs="auto"></Col>
            <Col xs="auto">
              <div className="d-flex">
                <SearchBox
                  placeholder="Search by name"
                  className="me-2"
                  onChange={handleSearchInputChange}
                />
                <DatePicker defaultValue="Mar 1, 2022" />
                <Button
                  variant="phoenix-secondary"
                  className="px-3 ms-2"
                  onClick={() => setOpenFilterModal(true)}
                >
                  <FontAwesomeIcon
                    icon={faFilter}
                    transform="down-3"
                    className="text-primary"
                  />
                </Button>
              </div>
              <LeadsFilterModal
                show={openFilterModal}
                handleClose={() => setOpenFilterModal(false)}
              />
            </Col>
          </Row>
          <CompanyTable />
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default Companies;
