import axios from 'axios';
import classNames from 'classnames';
import Button from 'components/base/Button';
import { errorHandler } from 'helpers/response-utils';
import { useEffect, useState } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as formik from 'formik';
import * as yup from 'yup';
import { source, leadValidationSchma } from 'static/Lead';

const EditLead = () => {
  const navigate = useNavigate();
  const { Formik } = formik;
  const { id } = useParams(); // Assuming you're using React Router for navigation
  const token = localStorage.getItem('token');
  const [leadStatus, setleadStatus] = useState([]);

  const [lead, setLead] = useState({
    name: '',
    email: '',
    phone: '',
    postcode: '',
    leadSource: '',
    leadsourceother: '',
    status: ''
  });
  const [users, setUsers] = useState([]);

  const schema = yup.object().shape(leadValidationSchma);

  useEffect(() => {
    getLeadStatus();
    getLead();
    getUsers();
  }, []);

  const getLeadStatus = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/leadstatus`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (response.data['status'] == false) {
      errorHandler(response.data);
      return false;
    }
    setleadStatus(response.data['data']['status']);
  };

  const getLead = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/leads/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.data.status === false) {
        errorHandler(response.data);
        return;
      }
      const leadData = response.data.data.lead;
      setLead({
        name: leadData.name,
        email: leadData.email,
        phone: leadData.phone,
        postcode: leadData.postcode,
        leadSource: source.includes(leadData.leadSource)
          ? leadData.leadSource
          : 'Others',
        leadsourceother: !source.includes(leadData.leadSource)
          ? leadData.leadSource
          : '',
        status: leadData.status
      });
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  const getUsers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/users`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.data.status === false) {
        errorHandler(response.data);
        return;
      }

      setUsers(response.data.data.user);
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  const onSubmit = async (values: any) => {
    const data = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      postcode: values.postcode,
      leadSource:
        values.leadSource == 'Others'
          ? values.leadsourceother
          : values.leadSource,
      status: values.status
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_ENDPOINT}/leads/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.data.status === false) {
        toast.error(response.data.error.message);
        return;
      }

      toast(response.data.data.message);
      navigateBack();
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  const navigateBack = () => {
    navigate('/user/crm/leads');
  };

  return (
    <div className="mb-9">
      <div className="border-bottom border-translucent mb-7 mx-n3 px-2 mx-lg-n6 px-lg-6">
        <Row>
          <Col xl={9}>
            <div className="d-sm-flex justify-content-between">
              <h2 className="mb-4">Edit Lead</h2>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col xl={9}>
          <Formik
            validationSchema={schema}
            onSubmit={values => onSubmit(values)}
            initialValues={lead}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              touched,
              errors,
              setValues
            }) => {
              useEffect(() => {
                setValues(lead);
              }, [lead]);
              return (
                <Form noValidate onSubmit={handleSubmit}>
                  <Row className={classNames('g-3', 'mb-6')}>
                    <Col sm={6} md={6}>
                      <FloatingLabel label="Name">
                        <Form.Control
                          id="name"
                          type="text"
                          name="name"
                          className="form-icon-input"
                          placeholder="Name"
                          value={values.name}
                          onChange={handleChange}
                          isValid={touched.name && !errors.name}
                          isInvalid={touched.name && !!errors.name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {' '}
                          {errors.name}{' '}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={6}>
                      <FloatingLabel label="Email address">
                        <Form.Control
                          id="email"
                          type="email"
                          name="email"
                          className="form-icon-input"
                          placeholder="name@example.com"
                          value={values.email}
                          onChange={handleChange}
                          isValid={touched.email && !errors.email}
                          isInvalid={touched.email && !!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {' '}
                          {errors.email}{' '}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={6}>
                      <FloatingLabel label="Phone">
                        <Form.Control
                          type="text"
                          placeholder="Phone"
                          className="input-spin-none"
                          id="phone"
                          name="phone"
                          value={values.phone}
                          onChange={handleChange}
                          isValid={touched.phone && !errors.phone}
                          isInvalid={touched.phone && !!errors.phone}
                        />
                        <Form.Control.Feedback type="invalid">
                          {' '}
                          {errors.phone}{' '}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={6}>
                      <FloatingLabel label="Postcode">
                        <Form.Control
                          type="text"
                          placeholder="Postcode"
                          className="input-spin-none"
                          id="postcode"
                          name="postcode"
                          value={values.postcode}
                          onChange={handleChange}
                          isValid={touched.postcode && !errors.postcode}
                          isInvalid={touched.postcode && !!errors.postcode}
                        />
                        <Form.Control.Feedback type="invalid">
                          {' '}
                          {errors.postcode}{' '}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={6}>
                      <FloatingLabel label="Lead source">
                        <Form.Select
                          name="leadSource"
                          value={values.leadSource}
                          onChange={handleChange}
                          isValid={touched.leadSource && !errors.leadSource}
                          isInvalid={touched.leadSource && !!errors.leadSource}
                        >
                          <option value=""> -- Select -- </option>
                          {source.length
                            ? source.map((lead, key) => (
                                <option value={lead} key={key}>
                                  {' '}
                                  {lead}{' '}
                                </option>
                              ))
                            : ''}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {' '}
                          {errors.status}{' '}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={6} hidden={values.leadSource != 'Others'}>
                      <FloatingLabel label="Other">
                        <Form.Control
                          type="text"
                          placeholder="Other"
                          className="input-spin-none"
                          name="leadsourceother"
                          value={values.leadsourceother}
                          onChange={handleChange}
                          isValid={
                            touched.leadsourceother && !errors.leadsourceother
                          }
                          isInvalid={
                            touched.leadsourceother && !!errors.leadsourceother
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {' '}
                          {errors.leadsourceother}{' '}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={6}>
                      <FloatingLabel label="Status">
                        <Form.Select
                          name="status"
                          value={values.status}
                          onChange={handleChange}
                          isValid={touched.status && !errors.status}
                          isInvalid={touched.status && !!errors.status}
                        >
                          <option value=""> -- Select -- </option>
                          {leadStatus.length
                            ? leadStatus.map(lead => (
                                <option value={lead['id']} key={lead['id']}>
                                  {' '}
                                  {lead['type']}{' '}
                                </option>
                              ))
                            : ''}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {' '}
                          {errors.status}{' '}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <div className="text-end">
                      <Button
                        variant="phoenix-primary"
                        className="me-2 px-6"
                        onClick={navigateBack}
                      >
                        Cancel
                      </Button>
                      <Button type="submit" variant="primary">
                        Update lead
                      </Button>
                    </div>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};

export default EditLead;
