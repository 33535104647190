import classNames from 'classnames';
import LeadByStatusChart from './LeadByStatusChart';
import ContactSourceItem from 'components/grid-list-items/ContactSourceItem';
import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';

const LeadByStatus = ({ leadByStatuseData }: { leadByStatuseData: any }) => {
  const total = useMemo(() => {
    if (leadByStatuseData) {
      return leadByStatuseData.reduce(
        (acc: any, item: any) => acc + item.value,
        0
      );
    } else {
      return 0;
    }
  }, [leadByStatuseData]);

  return (
    <Row>
      <Col sm={5} md={4} xxl={4} className="my-3 my-sm-0">
        <h3>Lead by Status</h3>
        <div className="position-relative d-flex flex-center mb-sm-4 mb-xl-0 echart-contact-by-source-container mt-sm-7 mt-lg-4 mt-xl-0">
          <LeadByStatusChart
            style={{ height: 245, width: '100%' }}
            leadByStatuseData={leadByStatuseData}
          />
          <div
            className="position-absolute rounded-circle bg-primary-subtle top-50 start-50 translate-middle d-flex flex-center"
            style={{ height: '100px', width: '100px' }}
          >
            <h3 className="mb-0 text-primary-dark fw-bolder">{total}</h3>
          </div>
        </div>
      </Col>
      <Col xs={12} sm={7} md={8} xxl={8} className="mb-md-3 mb-lg-0">
        <Row className="g-0">
          {leadByStatuseData
            ? leadByStatuseData.map((item: any, index: number) => (
                <Col xs={6} xl={4} key={item.name}>
                  <ContactSourceItem
                    value={item.value}
                    label={item.name}
                    color={item.color}
                    className={classNames('border-translucent', {
                      'border-bottom border-end':
                        index === 0 || index === 3 || index === 6,
                      'border-bottom border-end-md-0 border-end-xl':
                        index === 1 || index === 4 || index === 7,
                      'border-bottom border-end border-end-md border-end-xl-0':
                        index === 2 || index === 5 || index === 8,
                      'border-end': index === 9
                    })}
                  />
                </Col>
              ))
            : null}
        </Row>
      </Col>
    </Row>
  );
};

export default LeadByStatus;
